<template>
 <sdPageHeader title="Stroom & gas"></sdPageHeader>
 <Main>
  <a-row :gutter="25">
   <a-col :span="24">
    <WizardBlock>
     <a-row>
      <a-col :span="24">
       <WizardWrapper>
        <Steps
         :current="current"
         :isFinished="isFinished"
         :status="status"
         :steps="steps"
         size="small"
         @onDone="done"
         @onPrev="prev"
        >
         <template #ean>
          <sdModal :onCancel="handleConnectionAddModalCancel" :visible="showUsageEstimator"
                   title="Jaarverbruik schatten"
                   type="primary" width="60%">
           <ModalMain>
            <div class="create-account-form">
             <a-row :gutter="30">
              <a-col
               v-if="order.relation.type === 'CONSUMER'"
               :xs="24"
              >
               <EstimateButtonWrapper>
                <button
                 class="mr-1 mt-2 mb-2"
                 @click="setUsage(1)"
                >
                 <i
                  class="fas fa-male"
                 /><br><br>
                 1 persoon
                </button>
                <button
                 class="mr-1 mt-2 mb-2"
                 variant="outline-primary"
                 @click="setUsage(2)"
                >
                 <i
                  class="fas fa-male"
                 /><i
                 class="fas fa-male"
                /><br><br>
                 2 persoon
                </button>
                <button
                 class="mr-1 mt-2 mb-2" outlined
                 type="primary"
                 @click="setUsage(3)"
                >
                 <i
                  class="fas fa-male"
                 /><i
                 class="fas fa-male"
                /><i
                 class="fas fa-male"
                /><br><br>
                 3 persoon
                </button>
                <button
                 class="mr-1 mt-2 mb-2"
                 variant="outline-primary"
                 @click="setUsage(4)"
                >
                 <i
                  class="fas fa-male"
                 /><i
                 class="fas fa-male"
                /><i
                 class="fas fa-male"
                /><i
                 class="fas fa-male"
                /><br><br>
                 4 persoon
                </button>
                <button
                 class="mt-2 mb-2"
                 variant="outline-primary"
                 @click="setUsage(5)"
                >
                 <i
                  class="fas fa-male"
                 /><i
                 class="fas fa-male"
                /><i
                 class="fas fa-male"
                /><i
                 class="fas fa-male"
                /><i
                 class="fas fa-male"
                /><br><br>
                 5 persoon
                </button>
               </EstimateButtonWrapper>
              </a-col>
              <a-col
               v-if="order.relation.type === 'BUSINESS'"
               :xs="24"
              >
               <EstimateButtonWrapper>
                <button
                 class="mr-1 mt-2 mb-2"
                 variant="outline-primary"
                 @click="setUsage(6)"
                >
                 <i
                  class="fas fa-home"
                 /><br><br>
                 ZZP
                </button>
                <button
                 class="mr-1 mt-2 mb-2"
                 variant="outline-primary"
                 @click="setUsage(7)"
                >
                 <i
                  class="fas fa-store-alt"
                 /><br><br>
                 Eenmanszaak
                </button>
                <button
                 class="mr-1 mt-2 mb-2"
                 variant="outline-primary"
                 @click="setUsage(8)"
                >
                 <i
                  class="fas fa-store"
                 /><br><br>
                 Winkel
                </button>
                <button
                 class="mr-1 mt-2 mb-2"
                 variant="outline-primary"
                 @click="setUsage(9)"
                >
                 <i
                  class="fas fa-building"
                 /><br><br>
                 kantoorpand
                </button>
                <button
                 class="mt-2 mb-2"
                 variant="outline-primary"
                 @click="setUsage(10)"
                >
                 <i
                  class="fas fa-industry"
                 /><br><br>
                 Industrieel
                </button>
               </EstimateButtonWrapper>
              </a-col>
             </a-row>
            </div>
           </ModalMain>
          </sdModal>
          <sdModal :onCancel="handleConnectionAddModalCancel" :visible="visibleConnectionAddModal"
                   title="Connectie toevoegen"
                   type="primary" width="60%">
           <ModalMain>
            <div class="create-account-form">
             <a-form
              :model="addressConnectionForm"
              :rules="addressFormRules"
              layout="vertical"
              name="account"
             >
              <a-row :gutter="30">
               <a-col :sm="10" :xs="24">
                <a-form-item label="Postcode" name="postalCode">
                 <a-input v-model:value="addressConnectionForm.postalCode" placeholder=""/>
                </a-form-item>
               </a-col>
               <a-col :sm="4" :xs="24">
                <a-form-item label="Huisnummer" name="houseNumber">
                 <a-input v-model:value="addressConnectionForm.houseNumber" placeholder=""/>
                </a-form-item>
               </a-col>
               <a-col :sm="4" :xs="24">
                <a-form-item v-if="houseNumberAdditionsOptions.length > 0" label="Toevoeging"
                             name="houseNumberAddition">
                 <a-select v-model:value="addressConnectionForm.houseNumberAddition" :allowClear="true"
                           class="sDash_fullwidth-select"
                           size="large">
                  <a-select-option v-for="(item,index) in houseNumberAdditionsOptions"
                                   :key="index"
                                   :value="item.value" name="energyAllowSingleFuelElectricity">
                   {{ item.key }}
                  </a-select-option>
                 </a-select>
                </a-form-item>
               </a-col>
               <a-col :sm="6" :xs="24">
                <a-form-item label="&nbsp;">
                 <sdButton style="width:100%" type="primary" @click.prevent="addConnectionSearch">
                  <sdFeatherIcons size="16" type="search"/>
                  Zoeken
                 </sdButton>
                </a-form-item>
               </a-col>
              </a-row>
             </a-form>
            </div>
           </ModalMain>
          </sdModal>
          <a-form :model="{connections:order.connections}" layout="vertical" @finish="handleConnectionSelect">
           <sdCards title="Geselecteerde aansluitingen">
            <a-row :gutter="25">
             <a-col :xs="24">
              <sdCards headless>
               <a-row :gutter="30" class="mb-20">
                <a-col :xs="15"
                       style="vertical-align: middle;display: table-cell;align-self: center;">
                 <sdHeading as="h6" class="mb-5" style="font-size:14px">Stroom aansluitingen
                 </sdHeading>
                </a-col>
                <a-col :xs="9" style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                 <!--                                  <sdButton size="small" type="primary" class="ml-4"-->
                 <!--                                            @click.prevent="showModalConnection('ELECTRICITY')">-->
                 <!--                                    <sdFeatherIcons type="plus" size="14"/>-->
                 <!--                                    Toevoegen-->
                 <!--                                  </sdButton>-->
                 <a-button :disabled="loadingEdsn" :loading="loadingEdsn && edsnRestoreButton === 'ELECTRICITY'"
                           class="btn-signin"
                           size="large"
                           type="primary" @click.prevent="restoreConnection('ELECTRICITY')"> Herstellen
                 </a-button>
                </a-col>
               </a-row>
               <a-row v-if="order.connections.filter(i => i.marketSegment ==='ELECTRICITY').length > 0"
                      justify="center">
                <a-col :sm="24" :xs="24">
                 <ConnectionDetailsWrapper>
                  <a-col class="about-connection-wrapper">
                   <ConnectionCardWrapper headless>
                    <div v-for="(item, index) in order.connections" :key="index">
                     <CardWrapper v-if="item.marketSegment ==='ELECTRICITY'">
                      <figcaption
                       style="background: #f8f8f8;border-radius: 5px;padding:10px;">
                       <div class="mt-5 mb-0">
                        <a-row :gutter="30" class="mb-20">
                         <a-col :xs="15"
                                style="vertical-align: middle;display: table-cell;align-self: center;">
                          <strong>EAN: {{ item.ean }} - {{ item.gridOperator }}<br/>
                           Adres:
                           <span>{{ item.street }} {{
                             item.houseNumber
                            }} {{
                             item.houseNumberAddition ? item.houseNumberAddition : ''
                            }}, {{ item.postalCode }} {{ item.city }}, Nederland</span><br/>
                           Gebruiksdoel: {{ item.bagPurpose }}
                          </strong>
                         </a-col>
                         <a-col :xs="9"
                                style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                          <sdButton class="ml-4" size="small" type="danger"
                                    @click.prevent="handleConnectionDelete(index)">
                           <sdFeatherIcons size="14" type="trash"/>
                          </sdButton>
                         </a-col>
                        </a-row>
                        <a-row :gutter="30">
                         <a-col :xs="24">
                          <a-row :gutter="30">
                           <a-col :sm="6" :xs="24">
                            <a-form-item :name="['connections', index, 'capacityCode']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Aansluiting">
                             <a-select v-model:value="item.capacityCode"
                                       :allowClear="true"
                                       class="sDash_fullwidth-select"
                                       size="large">
                              <a-select-option
                               v-for="(item,index) in capacityCodeElectricityOptions"
                               :key="index"
                               :name="['connections', index, 'capacityCode']" :value="item.value">
                               {{ item.key }}
                              </a-select-option>
                             </a-select>
                            </a-form-item>
                           </a-col>
                           <a-col :sm="4" :xs="24">
                            <a-form-item :name="['connections', index, 'isResidenceFunction']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Woon-/verblijfsfunctie">
                             <a-select v-model:value="item.isResidenceFunction"
                                       :allowClear="true"
                                       class="sDash_fullwidth-select"
                                       size="large">
                              <a-select-option
                               v-for="(item,index) in defaultOptions"
                               :key="index"
                               :name="['connections', index, 'isResidenceFunction']" :value="item.value">
                               {{ item.key }}
                              </a-select-option>
                             </a-select>
                            </a-form-item>
                           </a-col>
                           <a-col :sm="4" :xs="24">
                            <a-form-item :name="['connections', index, 'switchType']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Inhuizing">
                             <a-select v-model:value="item.switchType"
                                       :allowClear="true"
                                       class="sDash_fullwidth-select"
                                       size="large">
                              <a-select-option
                               v-for="(item,index) in switchTypeOptions"
                               :key="index"
                               :name="['connections', index, 'switchType']" :value="item.value">
                               {{ item.key }}
                              </a-select-option>
                             </a-select>
                            </a-form-item>
                           </a-col>
                           <a-col :sm="4" :xs="24">
                            <a-form-item :name="['connections', index, 'meterType']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Dubbele meter">
                             <a-select v-model:value="item.meterType"
                                       :allowClear="true"
                                       class="sDash_fullwidth-select"
                                       size="large">
                              <a-select-option v-for="(item,index) in meterTypeOptions"
                                               :key="index"
                                               :name="['connections', index, 'meterType']" :value="item.value">
                               {{ item.key }}
                              </a-select-option>
                             </a-select>
                            </a-form-item>
                           </a-col>
                           <a-col :sm="4" :xs="24">
                            <a-form-item :name="['connections', index, 'hasReturn']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Teruglevering">
                             <a-select v-model:value="item.hasReturn"
                                       :allowClear="true"
                                       class="sDash_fullwidth-select"
                                       size="large"
                                       @handleConnectionChange="handleConnectionChange('HASRETURN',item.ean,$event)">
                              <a-select-option v-for="(item,index) in defaultOptions"
                                               :key="index"
                                               :name="['connections', index, 'hasReturn']" :value="item.value">
                               {{ item.key }}
                              </a-select-option>
                             </a-select>
                            </a-form-item>
                           </a-col>
                           <a-col :sm="2" :xs="24">
                            <a-form-item label="&nbsp;">
                             <sdButton style="width:100%" type="primary"
                                       @click.prevent="estimateUsage(item.ean)">
                              <sdFeatherIcons size="16" type="compass"/>
                             </sdButton>
                            </a-form-item>
                           </a-col>
                          </a-row>
                          <a-row :gutter="30">
                           <a-col v-if="item.meterType === 'SINGLE'" :sm="6"
                                  :xs="24">
                            <a-form-item :name="['connections', index, 'usageElectricitySingle']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Elektriciteit  enkel">
                             <a-input :key="index"
                                      v-model:value="item.usageElectricitySingle"
                                      :name="['connections', index, 'usageElectricitySingle']"
                                      addon-after="kWh"
                                      onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                      placeholder=""
                                      type="number"
                             />
                            </a-form-item>
                           </a-col>
                           <a-col v-if="item.meterType === 'DOUBLE'" :sm="6"
                                  :xs="24">
                            <a-form-item :name="['connections', index, 'usageElectricityHigh']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Elektriciteit  normaal">
                             <a-input v-model:value="item.usageElectricityHigh"
                                      :name="['connections', index, 'usageElectricityHigh']"
                                      addon-after="kWh"
                                      onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                      placeholder=""
                                      type="number"/>
                            </a-form-item>
                           </a-col>
                           <a-col v-if="item.meterType === 'DOUBLE'" :sm="6"
                                  :xs="24">
                            <a-form-item :name="['connections', index, 'usageElectricityLow']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Elektriciteit  dal">
                             <a-input v-model:value="item.usageElectricityLow"
                                      :name="['connections', index, 'usageElectricityLow']"
                                      addon-after="kWh"
                                      onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                      placeholder=""
                                      type="number"/>
                            </a-form-item>
                           </a-col>
                           <a-col v-if="item.meterType === 'SINGLE' && item.hasReturn" :sm="6"
                                  :xs="24">
                            <a-form-item :name="['connections', index, 'returnElectricitySingle']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Teruglevering  enkel">
                             <a-input v-model:value="item.returnElectricitySingle"
                                      :name="['connections', index, 'returnElectricitySingle']"
                                      addon-after="kWh"
                                      onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                      placeholder=""
                                      type="number"/>
                            </a-form-item>
                           </a-col>
                           <a-col v-if="item.meterType === 'DOUBLE' && item.hasReturn" :sm="6"
                                  :xs="24">
                            <a-form-item :name="['connections', index, 'returnElectricityHigh']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Teruglevering  normaal">
                             <a-input v-model:value="item.returnElectricityHigh"
                                      :name="['connections', index, 'returnElectricityHigh']"
                                      addon-after="kWh"
                                      onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                      placeholder=""
                                      type="number"/>
                            </a-form-item>
                           </a-col>
                           <a-col v-if="item.meterType === 'DOUBLE' && item.hasReturn" :sm="6"
                                  :xs="24">
                            <a-form-item :name="['connections', index, 'returnElectricityLow']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Teruglevering  dal">
                             <a-input v-model:value="item.returnElectricityLow"
                                      :name="['connections', index, 'returnElectricityLow']"
                                      addon-after="kWh"
                                      onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                      placeholder=""
                                      type="number"/>
                            </a-form-item>
                           </a-col>
                          </a-row>
                         </a-col>
                        </a-row>
                       </div>
                      </figcaption>
                     </CardWrapper>
                    </div>
                   </ConnectionCardWrapper>
                  </a-col>
                 </ConnectionDetailsWrapper>
                </a-col>
               </a-row>
               <a-row v-else>
                <a-col :xs="24">
                 <a-empty :image="simpleImage">
                  <template #description>
                                      <span>
                                        Geen aansluiting(en) geslecteerd
                                      </span>
                  </template>
                 </a-empty>
                </a-col>
               </a-row>
              </sdCards>
             </a-col>
            </a-row>
            <a-row :gutter="25">
             <a-col :xs="24">
              <sdCards headless>
               <a-row :gutter="30" class="mb-20">
                <a-col :xs="15"
                       style="vertical-align: middle;display: table-cell;align-self: center;">
                 <sdHeading as="h6" class="mb-5" style="font-size:14px">Gas aansluitingen</sdHeading>
                </a-col>
                <a-col :xs="9" style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                 <a-button :disabled="loadingEdsn" :loading="loadingEdsn && edsnRestoreButton === 'GAS'"
                           class="btn-signin"
                           size="large"
                           type="primary" @click.prevent="restoreConnection('GAS')"> Herstellen
                 </a-button>
                 <!--                                  <sdButton size="small" type="primary" class="ml-4"-->
                 <!--                                            @click.prevent="restoreConnection('GAS')">-->
                 <!--                                    <sdFeatherIcons type="plus" size="14"/>-->
                 <!--                                    Herstellen-->
                 <!--                                  </sdButton>-->
                </a-col>
               </a-row>
               <a-row v-if="order.connections.filter(i => i.marketSegment ==='GAS').length > 0"
                      justify="center">
                <a-col :sm="24" :xs="24">
                 <ConnectionDetailsWrapper>
                  <a-col class="about-connection-wrapper">
                   <ConnectionCardWrapper headless>
                    <div v-for="(item, index) in order.connections" :key="index">
                     <CardWrapper v-if="item.marketSegment ==='GAS'">
                      <figcaption
                       style="background: #f8f8f8;border-radius: 5px;padding:10px;">
                       <div class="mt-5 mb-0">
                        <a-row :gutter="30" class="mb-20">
                         <a-col :xs="15"
                                style="vertical-align: middle;display: table-cell;align-self: center;">
                          <strong>EAN: {{ item.ean }} - {{ item.gridOperator }}<br/>
                           Adres: <span>{{ item.street }} {{
                             item.houseNumber
                            }} {{
                             item.houseNumberAddition ? item.houseNumberAddition : ''
                            }}, {{ item.postalCode }} {{ item.city }}, Nederland</span><br/>Gebruiksdoel:
                           {{ item.bagPurpose }}
                          </strong>
                         </a-col>
                         <a-col :xs="9"
                                style="vertical-align: middle;display: flex;align-self: center;justify-content: end;">
                          <sdButton class="ml-4" size="small" type="danger"
                                    @click.prevent="handleConnectionDelete(index)">
                           <sdFeatherIcons size="14" type="trash"/>
                          </sdButton>
                         </a-col>
                        </a-row>
                        <a-row :gutter="30">
                         <a-col :xs="24">
                          <a-row :gutter="30">
                           <a-col :sm="6" :xs="24">
                            <a-form-item :name="['connections', index, 'capacityCode']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Aansluiting">
                             <a-select v-model:value="item.capacityCode" :allowClear="true"
                                       class="sDash_fullwidth-select"
                                       name="capacityCode"
                                       size="large">
                              <a-select-option
                               v-for="(capacityCode,capacityCodeIndex) in capacityCodeGasOptions"
                               :key="capacityCodeIndex"
                               :name="['connections', index, 'capacityCode']" :value="capacityCode.value">
                               {{ capacityCode.key }}
                              </a-select-option>
                             </a-select>
                            </a-form-item>
                           </a-col>
                           <a-col :sm="4" :xs="24">
                            <a-form-item :name="['connections', index, 'switchType']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Inhuizing">
                             <a-select v-model:value="item.switchType" :allowClear="true"
                                       class="sDash_fullwidth-select"
                                       size="large">
                              <a-select-option
                               v-for="(item,index) in switchTypeOptions"
                               :key="index"
                               :name="['connections', index, 'switchType']"
                               :value="item.value">
                               {{ item.key }}
                              </a-select-option>
                             </a-select>
                            </a-form-item>
                           </a-col>
                           <a-col :sm="6" :xs="24">
                            <a-form-item :name="['connections', index, 'usageGas']"
                                         :rules="{required: true,message: 'Dit veld is verplicht'}"
                                         label="Verbruik">
                             <a-input v-model:value="item.usageGas" addon-after="m3"
                                      onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}"
                                      onpaste="let pasteData = event.clipboardData.getData('text'); if(pasteData){pasteData.replace(/[^0-9]*/g,'');}"
                                      placeholder=""
                                      type="number"
                             />
                            </a-form-item>
                           </a-col>
                           <a-col :sm="2" :xs="24">
                            <a-form-item label="&nbsp;">
                             <sdButton style="width:100%" type="primary"
                                       @click.prevent="estimateUsage(item.ean)">
                              <sdFeatherIcons size="16" type="compass"/>
                             </sdButton>
                            </a-form-item>
                           </a-col>
                          </a-row>
                         </a-col>
                        </a-row>
                       </div>
                      </figcaption>
                     </CardWrapper>
                    </div>
                   </ConnectionCardWrapper>
                  </a-col>
                 </ConnectionDetailsWrapper>
                </a-col>
               </a-row>
               <a-row v-else>
                <a-col :xs="24">
                 <a-empty :image="simpleImage">
                  <template #description>
                                      <span>
                                        Geen aansluiting(en) geslecteerd
                                      </span>
                  </template>
                 </a-empty>
                </a-col>
               </a-row>
              </sdCards>
             </a-col>
            </a-row>
           </sdCards>
           <!--           <sdCards title="Productprijzing filters">-->
           <!--            <a-row :gutter="25">-->
           <!--             <a-col :xs="24">-->
           <!--              <sdCards headless style="padding:0!important">-->
           <!--               <a-row :gutter="30" class="mb-20">-->
           <!--                <a-col :xs="24" class="mb-20">-->
           <!--                 <sdAlerts-->
           <!--                  :closable="false"-->
           <!--                  :outlined="false"-->
           <!--                  :showIcon="true"-->
           <!--                  description="Indien er producten zijn toegevoegd die tarieven per startdatum geconfigureerd hebben dien je de startdatum hier al in te vullen."-->
           <!--                  message=""-->
           <!--                  type="info"-->
           <!--                 />-->
           <!--                </a-col>-->
           <!--                <a-col :md="8" :xs="24">-->
           <!--                 <a-form-item label="Gewenste startdatum">-->
           <!--                  <DatePickerWrapper>-->
           <!--                   <a-date-picker v-model:value="order.startDate" :format="dateFormat" class="w-100"/>-->
           <!--                  </DatePickerWrapper>-->
           <!--                 </a-form-item>-->
           <!--                </a-col>-->
           <!--               </a-row>-->
           <!--              </sdCards>-->
           <!--             </a-col>-->
           <!--            </a-row>-->
           <!--           </sdCards>-->
           <a-row v-if="showMultiSiteDisabledError">
            <a-col :xs="24">
             <sdAlerts
              :closable="false"
              :outlined="false"
              :showIcon="true"
              description="De huidige flow laat een maximum van 1 energie en 1 gas connectie per aanvraag toe."
              message="Maximaal aantal connecties overschreden!!!"
              type="error"
             />
            </a-col>
           </a-row>
           <ActionWrapper>
            <div class="step-action-wrap">
             <div class="step-action-inner">
              <a-row>
               <a-col :xs="24">
                <div class="steps-action">
                 <sdButton class="btn-prev" type="primary" @click.prevent="() => prevEan()">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                 <a-button :disabled="loadingOrdersOfferFlowProductCalculations"
                           :loading="loadingOrdersOfferFlowProductCalculations" class="btn-next"
                           html-type="submit"
                           type="primary">
                  <span>Volgende</span>
                  <sdFeatherIcons size="16" type="arrow-right"/>
                 </a-button>
                </div>
               </a-col>
              </a-row>
             </div>
            </div>
           </ActionWrapper>
          </a-form>
         </template>
         <template #product>
          <a-row :gutter="30">
           <a-col v-if="loadingOrdersOfferFlowProductCalculations" :xs="24">
            <div class="spin">
             <a-spin/>
            </div>
           </a-col>
           <template v-else-if="productCalculations.length">
            <a-col v-for="item in productCalculations" :key="item.id" :sm="6"
                   :xs="24" class="mb-30">
             <Suspense>
              <template #default>
               <ProductCards :data="item" :type="order.relation.type" @set-product-id="handleSelectProduct(item)"/>
              </template>
              <template #fallback>
               <sdCards headless>
                <a-skeleton :paragraph="{ rows: 22 }" active/>
               </sdCards>
              </template>
             </Suspense>
            </a-col>
           </template>
           <ActionWrapper>
            <div class="step-action-wrap">
             <div class="step-action-inner">
              <a-row>
               <a-col :xs="24">
                <div class="steps-action">
                 <sdButton class="btn-prev" type="primary" @click.prevent="() => prev()">
                  <sdFeatherIcons size="16" type="arrow-left"/>
                  <span>Vorige</span>
                 </sdButton>
                </div>
               </a-col>
              </a-row>
             </div>
            </div>
           </ActionWrapper>
          </a-row>
         </template>
         <template #relation>
          <Relation
           :key="relKey"
           ref="relationComponentRef"
           :disable-is-business="true"
           :is-order-create="true"
           :maxAge="order.product.maxAge?order.product.maxAge:null"
           :minAge="order.product.minAge?order.product.minAge:null"
           :relation="order.relation"
           :show-card-save-button="false"
           :show-footer="false"
           :show-global-save-button="true"
           :showHeader="false"
           type="ADD"
           @submit-external="handleRelationOnOrder($event)"
          ></Relation>
          <ActionWrapper>
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="primary" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Vorige</span>
                </sdButton>
                <sdButton class="btn-next" type="primary"
                          @click.prevent="$refs.relationComponentRef.submitExternal()">
                 <span>Volgende</span>
                 <sdFeatherIcons size="16" type="arrow-right"/>
                </sdButton>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
         <template #summary>
          <Main>
           <a-row :gutter="25">
            <a-col :md="10" :xs="24">
             <Suspense>
              <template #default>
               <ProductSummaryInfoCard :data="order.product"/>
              </template>
              <template #fallback>
               <sdCards headless>
                <a-skeleton :paragraph="{ rows: 3 }" active avatar/>
               </sdCards>
              </template>
             </Suspense>
             <Suspense v-if="!order.product.isProxy">
              <template #default>
               <RelationSummaryCard :order="order"/>
              </template>
             </Suspense>
            </a-col>
            <a-col :md="14" :xs="24">
             <Suspense v-if="order.product.isProxy">
              <template #default>
               <RelationSummaryCard :order="order"/>
              </template>
             </Suspense>
             <Aom v-if="!order.product.isProxy" :calculations="order" :type="order.relation.type"></Aom>
            </a-col>
           </a-row>
           <a-row v-if="order.connections.filter(i => i.marketSegment ==='ELECTRICITY').length > 0"
                  :gutter="25">
            <a-col :xs="24">
             <sdCards title="Stroom connecties">
              <a-form ref="formRef" :model="order.connections" layout="vertical">
               <a-row justify="center">
                <a-col :sm="24" :xs="24">
                 <ConnectionDetailsWrapper>
                  <a-col class="about-connection-wrapper">
                   <ConnectionCardWrapper headless>
                    <div v-for="(item, index) in order.connections" :key="index">
                     <CardWrapper v-if="item.marketSegment ==='ELECTRICITY'">
                      <a-form layout="vertical">
                       <figcaption
                        style="background: #f8f8f8;border-radius: 5px;padding:10px;">
                        <div class="mt-5 mb-0">
                         <a-row :gutter="30" class="mb-20">
                          <a-col :xs="24"
                                 style="vertical-align: middle;display: table-cell;align-self: center;">
                           <strong>EAN: {{ item.ean }} - {{ item.gridOperator }}<br/>
                            Adres:
                            <span>{{ item.street }} {{
                              item.houseNumber
                             }} {{
                              item.houseNumberAddition ? item.houseNumberAddition : ''
                             }}, {{ item.postalCode }} {{
                              item.city
                             }}, Nederland</span><br/>
                            Gebruiksdoel: {{ item.bagPurpose }}
                           </strong>
                          </a-col>
                         </a-row>
                         <a-row :gutter="30">
                          <a-col :xs="24">
                           <a-form :model="form" layout="vertical">
                            <a-row :gutter="30">
                             <a-col :sm="6" :xs="24">
                              <a-form-item label="Aansluiting">
                               <a-select v-model:value="item.capacityCode" :disabled="true"
                                         class="sDash_fullwidth-select"
                                         size="large">
                                <a-select-option v-for="(item,index) in capacityCodeElectricityOptions"
                                                 :key="index"
                                                 :name="`e-capacityCode${index}`" :value="item.value">
                                 {{ item.key }}
                                </a-select-option>
                               </a-select>
                              </a-form-item>
                             </a-col>
                             <a-col :sm="6" :xs="24">
                              <a-form-item label="Woon-/verblijfsfunctie">
                               <a-select v-model:value="item.isResidenceFunction"
                                         :disabled="true"
                                         class="sDash_fullwidth-select"
                                         size="large">
                                <a-select-option
                                 v-for="(item,index) in defaultOptions"
                                 :key="index"
                                 :name="['connections', index, 'isResidenceFunction']" :value="item.value">
                                 {{ item.key }}
                                </a-select-option>
                               </a-select>
                              </a-form-item>
                             </a-col>
                             <a-col :sm="4" :xs="24">
                              <a-form-item label="Inhuizing">
                               <a-select v-model:value="item.switchType"
                                         :disabled="true"
                                         class="sDash_fullwidth-select"
                                         size="large">
                                <a-select-option
                                 v-for="(item,index) in switchTypeOptions"
                                 :key="index"
                                 :name="['connections', index, 'switchType']" :value="item.value">
                                 {{ item.key }}
                                </a-select-option>
                               </a-select>
                              </a-form-item>
                             </a-col>
                             <a-col :sm="4" :xs="24">
                              <a-form-item label="Dubbele meter">
                               <a-select v-model:value="item.meterType"
                                         :disabled="true"
                                         class="sDash_fullwidth-select"
                                         size="large">
                                <a-select-option
                                 v-for="(item,index) in meterTypeOptions"
                                 :key="index"
                                 :name="['connections', index, 'meterType']" :value="item.value">
                                 {{ item.key }}
                                </a-select-option>
                               </a-select>
                              </a-form-item>
                             </a-col>
                             <a-col :sm="4" :xs="24">
                              <a-form-item label="Teruglevering">
                               <a-select v-model:value="item.hasReturn"
                                         :disabled="true"
                                         class="sDash_fullwidth-select"
                                         size="large">
                                <a-select-option
                                 v-for="(item,index) in defaultOptions"
                                 :key="index"
                                 :name="['connections', index, 'hasReturn']" :value="item.value">
                                 {{ item.key }}
                                </a-select-option>
                               </a-select>
                              </a-form-item>
                             </a-col>
                            </a-row>
                            <a-row :gutter="30">
                             <a-col v-if="item.meterType === 'SINGLE'" :sm="6"
                                    :xs="24">
                              <a-form-item label="Elektriciteit  enkel">
                               <a-input :key="index"
                                        v-model:value="item.usageElectricitySingle"
                                        :disabled="true"
                                        :name="['connections', index, 'usageElectricitySingle']"
                                        addon-after="kWh"
                                        placeholder=""
                                        type="number"
                               />
                              </a-form-item>
                             </a-col>
                             <a-col v-if="item.meterType === 'DOUBLE'" :sm="6"
                                    :xs="24">
                              <a-form-item label="Elektriciteit  normaal">
                               <a-input v-model:value="item.usageElectricityHigh"
                                        :disabled="true"
                                        :name="['connections', index, 'usageElectricityHigh']"
                                        addon-after="kWh"
                                        placeholder=""
                                        type="number"/>
                              </a-form-item>
                             </a-col>
                             <a-col v-if="item.meterType === 'DOUBLE'" :sm="6"
                                    :xs="24">
                              <a-form-item label="Elektriciteit  dal">
                               <a-input v-model:value="item.usageElectricityLow"
                                        :disabled="true"
                                        :name="['connections', index, 'usageElectricityLow']"
                                        addon-after="kWh"
                                        placeholder=""
                                        type="number"/>
                              </a-form-item>
                             </a-col>
                             <a-col v-if="item.meterType === 'SINGLE' && item.hasReturn" :sm="6"
                                    :xs="24">
                              <a-form-item label="Teruglevering  enkel">
                               <a-input v-model:value="item.returnElectricitySingle"
                                        :disabled="true"
                                        :name="['connections', index, 'returnElectricitySingle']"
                                        addon-after="kWh"
                                        placeholder=""
                                        type="number"/>
                              </a-form-item>
                             </a-col>
                             <a-col v-if="item.meterType === 'DOUBLE' && item.hasReturn" :sm="6"
                                    :xs="24">
                              <a-form-item label="Teruglevering  normaal">
                               <a-input v-model:value="item.returnElectricityHigh"
                                        :disabled="true"
                                        :name="['connections', index, 'returnElectricityHigh']"
                                        addon-after="kWh"
                                        placeholder=""
                                        type="number"/>
                              </a-form-item>
                             </a-col>
                             <a-col v-if="item.meterType === 'DOUBLE' && item.hasReturn" :sm="6"
                                    :xs="24">
                              <a-form-item label="Teruglevering  dal">
                               <a-input v-model:value="item.returnElectricityLow"
                                        :disabled="true"
                                        :name="['connections', index, 'returnElectricityLow']"
                                        addon-after="kWh"
                                        placeholder=""
                                        type="number"/>
                              </a-form-item>
                             </a-col>
                            </a-row>
                           </a-form>
                          </a-col>
                         </a-row>
                        </div>
                       </figcaption>
                      </a-form>
                     </CardWrapper>
                    </div>
                   </ConnectionCardWrapper>
                  </a-col>
                 </ConnectionDetailsWrapper>
                </a-col>
               </a-row>
              </a-form>
             </sdCards>
            </a-col>
           </a-row>
           <a-row v-if="order.connections.filter(i => i.marketSegment ==='GAS').length > 0" :gutter="25">
            <a-col :xs="24">
             <sdCards title="Gas connecties">
              <a-row justify="center">
               <a-col :sm="24" :xs="24">
                <ConnectionDetailsWrapper>
                 <a-col class="about-connection-wrapper">
                  <ConnectionCardWrapper headless>
                   <div v-for="(item, index) in order.connections" :key="index">
                    <CardWrapper v-if="item.marketSegment ==='GAS'">
                     <a-form layout="vertical">
                      <figcaption
                       style="background: #f8f8f8;border-radius: 5px;padding:10px;">
                       <div class="mt-0 mb-0">
                        <a-row :gutter="30" class="mb-20">
                         <a-col :xs="24"
                                style="vertical-align: middle;display: table-cell;align-self: center;">
                          <strong>EAN: {{ item.ean }} - {{ item.gridOperator }}<br/>
                           Adres: <span>{{ item.street }} {{
                             item.houseNumber
                            }} {{
                             item.houseNumberAddition ? item.houseNumberAddition : ''
                            }}, {{ item.postalCode }} {{ item.city }}, Nederland</span><br/>Gebruiksdoel:
                           {{ item.bagPurpose }}
                          </strong>
                         </a-col>
                        </a-row>
                        <a-row :gutter="30">
                         <a-col :xs="24">
                          <a-row :gutter="30">
                           <a-col :sm="6" :xs="24">
                            <a-form-item label="Aansluiting">
                             <a-select v-model:value="item.capacityCode" :disabled="true"
                                       class="sDash_fullwidth-select"
                                       name="capacityCode"
                                       size="large">
                              <a-select-option
                               v-for="(capacityCode,capacityCodeIndex) in capacityCodeGasOptions"
                               :key="capacityCodeIndex"
                               :name="['connections', index, 'capacityCode']" :value="capacityCode.value">
                               {{ capacityCode.key }}
                              </a-select-option>
                             </a-select>
                            </a-form-item>
                           </a-col>
                           <a-col :sm="4" :xs="24">
                            <a-form-item label="Inhuizing">
                             <a-select v-model:value="item.switchType" :disabled="true"
                                       class="sDash_fullwidth-select"
                                       size="large">
                              <a-select-option
                               v-for="(item,index) in switchTypeOptions"
                               :key="index" :name="['connections', index, 'switchType']"
                               :value="item.value">
                               {{ item.key }}
                              </a-select-option>
                             </a-select>
                            </a-form-item>
                           </a-col>
                           <a-col :sm="6" :xs="24">
                            <a-form-item label="Verbruik">
                             <a-input v-model:value="item.usageGas" :disabled="true"
                                      addon-after="m3" placeholder="" type="number"/>
                            </a-form-item>
                           </a-col>
                          </a-row>
                         </a-col>
                        </a-row>
                       </div>
                      </figcaption>
                     </a-form>
                    </CardWrapper>
                   </div>
                  </ConnectionCardWrapper>
                 </a-col>
                </ConnectionDetailsWrapper>
               </a-col>
              </a-row>
             </sdCards>
            </a-col>
           </a-row>
           <a-row :gutter="25">
            <a-col :xs="24">
             <sdCards title="Product vragen">
              <a-form ref="flowQuestionsForm" :model="{flowQuestions:order.flowQuestions}"
                      layout="vertical">
               <a-row :gutter="25">
                <a-col v-for="(item,index) in order.flowQuestions" :key="index" :md="12" :xs="24">
                 <a-form-item v-if="item.inputType === 'STARTDATE'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="item.isRequired ? {required: true,message: 'Dit veld is verplicht'} : ''">
                  <DatePickerWrapper>
                   <a-date-picker v-model:value="item.value" :defaultPickerValue="getDefaultPicker()"
                                  :disabled-date="disabledStartDate"
                                  :format="dateFormat" class="w-100"/>
                  </DatePickerWrapper>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'DATE'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="item.isRequired ? {required: true,message: 'Dit veld is verplicht'} : ''">
                  <DatePickerWrapper>
                   <a-date-picker v-model:value="item.value" :defaultPickerValue="moment()" :format="dateFormat"
                                  class="w-100"/>
                  </DatePickerWrapper>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'TEXT'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="item.isRequired ? {required: true,message: 'Dit veld is verplicht'} : ''">
                  <a-input v-model:value="item.value" placeholder=""/>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'INTEGER'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="item.isRequired ? [{required: true,message: 'Dit veld is verplicht'},{validator:integerValidator}] : {validator:integerValidator}"
                              onkeydown="if(event.key==='.'||event.key===','){event.preventDefault();}">
                  <a-input v-model:value="item.value" placeholder="" step="1" type="text"/>
                 </a-form-item>
                 <a-form-item v-if="item.inputType === 'SELECT'" :label="item.displayName"
                              :name="['flowQuestions', index, 'value']"
                              :rules="item.isRequired ? {required: true,message: 'Dit veld is verplicht'} : ''">
                  <a-select v-model:value="item.value" :allowClear="true" class="sDash_fullwidth-select"
                            size="large">
                   <a-select-option v-for="(option,index) in item.inputValues"
                                    :key="index"
                                    :name="['flowQuestions', index, 'value']" :value="option"> {{ option }}
                   </a-select-option>
                  </a-select>
                 </a-form-item>
                </a-col>
               </a-row>
              </a-form>
             </sdCards>
            </a-col>
           </a-row>
           <a-row v-if="order.signatureType === 'SIGNATURE'" :gutter="25">
            <a-col :xs="24">
             <sdCards title="Ondertekenen">
              <a-row :gutter="25">
               <a-col :xs="12" class="signature align-center">
                <Vue3Signature ref="signature1" :disabled="sigState.disabled" :h="'200px'" :sigOption="sigState.option"
                               :w="'450px'"></Vue3Signature>
                <div v-if="signatureError" class="ant-form-item-explain ant-form-item-explain-error">
                 <div role="alert">Uw handtekening is vereist</div>
                </div>
                <sdButton class="mt-5" size="small" type="gray" @click.prevent="clearSignature">
                 <span>Opnieuw</span>
                </sdButton>
               </a-col>
               <a-col :xs="12" class="mb-5">
                <a-form ref="customerApprovalsForm" :model="order"
                        layout="vertical">
                 <a-form-item v-for="(item,index) in order.product.customerApprovals" :key="index"
                              :name="['product','customerApprovalsValidation', index]"
                              :rules="{ validator: checkCheckBox }" class="checkbox-group"
                              label=""
                              style="padding-bottom:0px;margin-bottom:0px">
                  <a-checkbox v-model:checked="order.product.customerApprovalsValidation[index]"
                              :name="['product','customerApprovalsValidation', index]">
                   {{ item }}
                  </a-checkbox>
                 </a-form-item>
                </a-form>
               </a-col>
              </a-row>
             </sdCards>
            </a-col>
           </a-row>
          </Main>
          <ActionWrapper v-if="!isFinished">
           <div class="step-action-wrap">
            <div class="step-action-inner">
             <a-row>
              <a-col :xs="24">
               <div class="steps-action">
                <sdButton class="btn-prev" type="light" @click="() => prev()">
                 <sdFeatherIcons size="16" type="arrow-left"/>
                 <span>Terug</span>
                </sdButton>
                <a-button :disabled="loadingCreateOrder" :loading="loadingCreateOrder" class="btn-signin" size="large"
                          type="primary"
                          @click="handleCreateOrder"> Aanmaken
                </a-button>
               </div>
              </a-col>
             </a-row>
            </div>
           </div>
          </ActionWrapper>
         </template>
        </Steps>
       </WizardWrapper>
      </a-col>
     </a-row>
    </WizardBlock>
   </a-col>
  </a-row>
 </Main>
</template>
<script>
import {
 CardWrapper,
 ConnectionCardWrapper,
 ConnectionDetailsWrapper,
 EstimateButtonWrapper,
 FigureWizards,
 OrderSummary,
 ProductTable,
 WizardBlock,
 WizardWrapper,
} from './Style';
import {BasicFormWrapper, DatePickerWrapper, Main, ModalMain} from '../styled';
import Steps from "./component/steps";
import {useStore} from "vuex";
import {computed, onMounted, reactive, ref, watchEffect} from "vue";
import Relation from "../../components/relation/Relation";
import ProductCards from "./component/ProductCards";
import FlowCards from "./component/FlowCards";
import {
 capacityCodeElectricityOptions,
 capacityCodeGasOptions,
 defaultOptions,
 meterTypeOptions,
 switchTypeOptions,
 usageEstimationOptions
} from "../../utility/enums";
import {ProductOverviewTable} from "./component/style";
import {ActionWrapper} from "../../components/steps/style";
import {checkCheckBox, integerValidator, postalCodeValidator} from "../../utility/validators";
import Aom from "../../components/aom/Aom";
import ProductInfoCard from "./component/ProductInfoCard";
import ProductSummaryInfoCard from "./component/ProductSummaryInfoCard";
import ConnectionTable from "./component/ConnectionTable";
import RelationSummaryCard from "./component/RelationSummaryCard";
import RelationCard from "./component/RelationCard";
import moment from "moment";
import {Empty} from "ant-design-vue";
import {useRouter} from "vue-router";

const OrderCreateEnergy = {
 name: 'OrderCreateEnergy',
 components: {
  ConnectionTable,
  RelationSummaryCard,
  CardWrapper,
  WizardBlock,
  Main,
  ModalMain,
  Steps,
  FigureWizards,
  WizardWrapper,
  BasicFormWrapper,
  ProductTable,
  OrderSummary,
  ConnectionDetailsWrapper,
  Relation,
  ProductCards,
  ConnectionCardWrapper,
  ProductInfoCard,
  RelationCard,
  ProductOverviewTable,
  ActionWrapper,
  EstimateButtonWrapper,
  Aom,
  FlowCards,
  ProductSummaryInfoCard,
  DatePickerWrapper,
 },
 data() {
  return {
   customStyle: 'background: #f7f7f7;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',
   debounce: null,
   selectedAddress: {
    postalCode: '',
    houseNumber: '',
   },
   dateFormat: 'DD-MM-YYYY',
   simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
  };
 },
 setup() {
  const {state, dispatch} = useStore();
  const router = useRouter()
  const status = ref("process");
  const isFinished = ref(false);
  const current = ref(0);
  const relationComponentRef = ref();
  let relKey = ref(0);
  const order = computed(() => state.order.orderCreate);
  const productCalculations = computed(() => state.order.productCalculations);
  const loadingCreateOrder = computed(() => state.order.loadingCreateOrder);
  const loadingOrdersOfferFlowProductCalculations = computed(() => state.order.loadingOrdersOfferFlowProductCalculations);
  const loadingPreFillRelationOnOrder = computed(() => state.order.loadingPreFillRelationOnOrder);
  const loadingHouseNumberAdditions = computed(() => state.globalSearch.loadingHouseNumberAdditions);
  const loadingEdsn = computed(() => state.edsn.loading);
  const edsnRestoreButton = ref(null);
  const visibleConnectionAddModal = ref(false);
  const flowQuestionsForm = ref();
  const customerApprovalsForm = ref();
  const signatureError = ref(false);
  const signature1 = ref();
  const showMultiSiteDisabledError = ref(false);

  const next = () => {
   status.value = "process";
   current.value = current.value + 1;
  };

  const prev = () => {
   status.value = "process";
   current.value = current.value - 1;
  };

  const prevEan = () => {
   dispatch('resetCreateOrderConnections');
   status.value = "process";
   current.value = current.value - 1;
  };

  const done = () => {
   const confirm = window.confirm("Are sure to submit order?");
   if (confirm) {
    status.value = "finish";
    isFinished.value = true;
    current.value = 0;
   }
  };

  const form = reactive({
   lastName: '',
   firstName: '',
   middleName: '',
  });

  const addressForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL'
  });
  const addressConnectionForm = reactive({
   postalCode: '',
   houseNumber: '',
   houseNumberAddition: '',
   country: 'NL',
   type: '',
  });
  const addressFormRules = {
   postalCode: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: postalCodeValidator,
    },
   ],
   houseNumber: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
    {
     validator: integerValidator,
    },
   ],
   houseNumberAddition: [
    {
     required: true,
     message: 'Dit veld is verplicht',
    },
   ],
  };

  const globalSearch = () => {
   dispatch('globalSearch', {
    postalCode: addressForm.postalCode,
    houseNumber: addressForm.houseNumber,
    houseNumberAddition: addressForm.houseNumberAddition !== null && addressForm.houseNumberAddition !== 'geen' ? addressForm.houseNumberAddition : null,
    country: 'NL',
    type: 'ENERGY',
   });
   dispatch('resetCreateOrderConnections');
  };
  const restoreConnection = (type) => {
   edsnRestoreButton.value = type;
   dispatch('searchEdsn', {
    postalCode: order.value.relation.postalCode,
    houseNumber: order.value.relation.houseNumber,
    houseNumberAddition: order.value.relation.houseNumberAddition && order.value.relation.houseNumberAddition !== 'geen' ? order.value.relation.houseNumberAddition : null,
    country: 'NL',
    type: type,
   });
  }
  const addConnectionSearch = async () => {
   await dispatch('searchEdsn', {
    postalCode: addressConnectionForm.postalCode,
    houseNumber: addressConnectionForm.houseNumber,
    houseNumberAddition: addressConnectionForm.houseNumberAddition && addressForm.houseNumberAddition !== 'geen' ? addressConnectionForm.houseNumberAddition : null,
    country: 'NL',
    type: addressConnectionForm.type,
   });
   visibleConnectionAddModal.value = false;
  };

  const handleSelectRelation = async (data, type) => {
   const relation = data;
   if (type) {
    relation.type = type;
   }
   if (type === 'CONSUMER') {
    relation.companyName = null;
    relation.companyCocNumber = null;
   }
   await dispatch('resetRelationOnOrder');
   await dispatch('preFillRelationOnOrder', {value: relation, stepRoute: next});
  };

  const handleConnectionDelete = (index) => {
   showMultiSiteDisabledError.value = false;
   dispatch('deleteEan', index);
  };

  const handleConnectionAddModalCancel = () => {
   visibleConnectionAddModal.value = false;
  };

  const showModalConnection = (type) => {
   addressConnectionForm.type = type;
   visibleConnectionAddModal.value = true;
  }

  // const handleSelectFlow = async (data) => {
  //  await dispatch("cleanGlobalSearch");
  //  await dispatch('resetCreateOrderConnections');
  //  await dispatch('setOrderFlow', data);
  //  next();
  // };

  const handleConnectionSelect = () => {
   relKey.value = relKey.value + 1;
   showMultiSiteDisabledError.value = false;
   const gasConnections = order.value.connections.filter(g => g.marketSegment === 'GAS');
   const electricityConnections = order.value.connections.filter(e => e.marketSegment === 'ELECTRICITY');
   if (gasConnections.length < 2 && electricityConnections.length < 2) {
    dispatch('getProductCalculations', {stepRoute: next});
   } else {
    showMultiSiteDisabledError.value = true;
   }
  }

  const handleSelectProduct = (item) => {
   dispatch('setProductOnReOrder', {item: item, stepRoute: next})
  };

  const handleRelationOnOrder = (data) => {
   dispatch('addRelationToOrder', {value: data, stepRoute: next})
  };

  const orderCreateSuccessRoute = () => {
   router.push('/order');
  };

  const handleCreateOrder = () => {
   const orderValidators = [flowQuestionsForm.value.validate()]
   signatureError.value = false;
   if (order.value.signatureType === 'SIGNATURE') {
    orderValidators.push(customerApprovalsForm.value.validate());
   }
   Promise.all(orderValidators).then(() => {
    if (order.value.signatureType === 'SIGNATURE') {
     if (!signature1.value.isEmpty()) {
      dispatch('setSignatureData', signature1.value.save('image/svg+xml'))
      dispatch('createOrder', {successRoute: orderCreateSuccessRoute})
     } else {
      signatureError.value = true;
     }
    } else {
     dispatch('createOrder', {successRoute: orderCreateSuccessRoute})
    }
   })
    .catch(() => dispatch('orderCreateValidationError'))
  };

  const showUsageEstimator = ref(false);
  const selectedEanToEstimateUsage = ref(null)
  const estimateUsage = (ean) => {
   selectedEanToEstimateUsage.value = ean;
   showUsageEstimator.value = true;
  };

  const setUsage = (selection) => {
   const connection = order.value.connections.find(i => i.ean === selectedEanToEstimateUsage.value);
   const index = order.value.connections.indexOf(connection);
   if (order.value.connections[index].marketSegment === "ELECTRICITY" && order.value.connections[index].meterType === "SINGLE") {
    order.value.connections[index].usageElectricitySingle = usageEstimationOptions[selection].electricity
   }

   if (order.value.connections[index].marketSegment === "ELECTRICITY" && order.value.connections[index].meterType === "DOUBLE") {
    order.value.connections[index].usageElectricityHigh = (usageEstimationOptions[selection].electricity / 2)
    order.value.connections[index].usageElectricityLow = (usageEstimationOptions[selection].electricity / 2)
   }

   if (order.value.connections[index].marketSegment === "GAS") {
    order.value.connections[index].usageGas = (usageEstimationOptions[selection].gas)
   }

   showUsageEstimator.value = false;
  };

  watchEffect(() => {
   const activeElement = document.querySelectorAll(".ant-steps-item-active");
   const successElement = document.querySelectorAll(
    ".ant-steps-item-finish"
   );

   activeElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     if (bgImage.classList.contains("success-step-item")) {
      bgImage.classList.remove("success-step-item");
     } else {
      bgImage.classList.remove("wizard-step-item");
     }
     bgImage.classList.add("wizard-steps-item-active");
    }
   });

   successElement.forEach((element) => {
    if (element.previousSibling) {
     const bgImage = element.previousSibling;
     bgImage.classList.remove("wizard-steps-item-active");
     bgImage.classList.add("success-step-item");
    }
   });
  });

  const pageSize = ref(10);

  const onShowSizeChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const onHandleChange = (current, pageSize) => {
   current.value = current;
   pageSize.value = pageSize;
  };

  const sigState = reactive({
   count: 0,
   option: {
    penColor: "rgb(0, 0, 0)",
    backgroundColor: "rgb(220,220,220)",
   },
   disabled: false
  })

  const clearSignature = () => {
   signature1.value.clear()
  }

  const disabledStartDate = (current) => {
   const endDate = moment().add(order.value.product.energySwitchWindowMaxDays > 0 ? order.value.product.energySwitchWindowMaxDays : 90, 'd')
   const startDate = moment().add(order.value.product.energySwitchWindowMinDays > 0 ? (order.value.product.energySwitchWindowMinDays - 1) : 39, 'd')
   return !current.isBetween(startDate, endDate);
  };

  const disabledDate = (current) => {
   return moment() >= moment(current);
  };

  const getDefaultPicker = () => {
   return moment(new Date()).add(order.value.product.energySwitchWindowDefaultDays > 0 ? order.value.product.energySwitchWindowDefaultDays : 40, 'd');
  };


  onMounted(async () => {
   if (order.value.connections.length < 1) {
    await router.push('/order');
   }
  });

  return {
   sigState,
   steps: [
    {
     title: "Aansluitingen",
     content: "ean",
    },
    {
     title: "Product",
     content: "product",
    },
    {
     title: "Klantgegevens",
     content: "relation",
    },
    {
     title: "Overzicht",
     content: "summary",
    },
   ],
   disabledStartDate,
   clearSignature,
   signature1,
   addressForm,
   addressConnectionForm,
   addressFormRules,
   order,
   visibleConnectionAddModal,
   productCalculations,
   relationComponentRef,
   showUsageEstimator,
   switchTypeOptions,
   meterTypeOptions,
   defaultOptions,
   capacityCodeElectricityOptions,
   capacityCodeGasOptions,
   usageEstimationOptions,
   handleSelectRelation,
   handleConnectionDelete,
   handleConnectionAddModalCancel,
   showModalConnection,
   globalSearch,
   addConnectionSearch,
   done,
   prev,
   next,
   handleConnectionSelect,
   handleSelectProduct,
   handleRelationOnOrder,
   handleCreateOrder,
   setUsage,
   estimateUsage,
   isFinished,
   current,
   onHandleChange,
   onShowSizeChange,
   pageSize,
   form,
   moment,
   loadingOrdersOfferFlowProductCalculations,
   loadingPreFillRelationOnOrder,
   prevEan,
   flowQuestionsForm,
   customerApprovalsForm,
   checkCheckBox,
   signatureError,
   loadingCreateOrder,
   getDefaultPicker,
   disabledDate,
   restoreConnection,
   loadingEdsn,
   edsnRestoreButton,
   showMultiSiteDisabledError,
   loadingHouseNumberAdditions,
   relKey,
   integerValidator,
  };
 },
};

export default OrderCreateEnergy;
</script>
<style>
.steps-content {
 display: block !important;
}

.status-badge {
 font-size: 12px;
 padding: 0 12.41px;
 line-height: 1.9;
 font-weight: 500;
 border-radius: 12px;
 text-transform: capitalize;
 display: inline-block !important;
 background-color: #519c79;
 color: #ffffff;
}

.ant-collapse-item {
 margin-bottom: 15px !important;;
}

.ant-collapse .collapse-title {
 width: 100% !important;
 display: flex !important;
 align-items: baseline !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input[disabled] {
 color: #000000 !important;;
}

.signature canvas {
 width: 400px;
 height: 200px;
}

.checkbox-group .ant-form-item-control-input {
 min-height: 0px !important;
}

.ant-calendar-picker-container {
 z-index: 999999 !important;
}
</style>
